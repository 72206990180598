<template>
  <div
    id="sidebar"
    class="hidden md:block w-[220px] border-r bg-white shrink-0 pb-10"
  >
    <div
      class="sticky"
      :class="{
        'top-2': !hasStickyNavbar,
        'top-[calc(1.25rem+67px)]': hasStickyNavbar
      }"
    >
      <airtable-status-banner class="px-5" />

      <div
        v-for="(section, sectionName) in sectionsList"
        :key="sectionName"
        class="mt-5 px-3"
      >
        <p class="text-gray-400 uppercase text-xs mb-2 flex items-center px-2">
          {{ sectionName }}
        </p>
        <ul class="text-sm">
          <li
            v-for="(sectionItem, sectionItemName) in section"
            :key="sectionItemName"
            class="mt-1 flex items-center relative hover:cursor hover:no-underline p-2 rounded-lg"
            :class="[{'text-blue-600 font-semibold bg-blue-50':sectionItem.active, 'text-gray-600 hover:bg-blue-50/50':!sectionItem.active}, sectionItem.textClass]"
          >
            <Icon
              :class="[{'text-blue-600':sectionItem.active, 'text-gray-400':!sectionItem.active}, sectionItem.iconClass]"
              class="w-5 h-5 ml-1 mr-3"
              :name="sectionItem.icon"
            />
            <span class="block truncate">
              {{ sectionItemName }}
            </span>
            <NuxtLink
              v-if="sectionItem.route"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0"
              :to="sectionItem.route"
            />
            <a
              v-else-if="sectionItem.href"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0"
              target="_blank"
              :href="sectionItem.href"
            />
            <div
              v-else-if="sectionItem.action"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0 cursor-pointer"
              @click="sectionItem.action"
            />
          </li>
        </ul>
      </div>
      <div class="px-3 mt-5">
        <UpgradeCard />
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import AirtableStatusBanner from '~/components/airtable/AirtableStatusBanner.vue'

const crisp = useCrisp()
const route = useRoute()
const hasStickyNavbar = computed(() => route.meta.stickyNavbar)

const sectionsList = computed(() => {
  const sections = {
    Main: {
      'Forms': {
        icon: 'heroicons:document',
        route: {name: 'home'}
      }
    },
    'Contact': {
      'Send us a message':
        {
          icon: 'heroicons:chat-bubble-left-ellipsis',
          action:
            () => {
              crisp.openAndShowChat()
            }
        },
    }
  }

  // Set property active_route to true if it is active
  for (const sectionName in sections) {
    for (const sectionItemName in sections[sectionName]) {
      sections[sectionName][sectionItemName].active = isActive(sections[sectionName][sectionItemName].route)
    }
  }

  return sections
})

const isActive = (secRoute) => {
  const routeName = secRoute?.name || null
  if (routeName === 'forms-slug-show-share') {
    return secRoute?.params?.slug === route.params?.slug
  }
  return route.name === routeName
}
</script>
